import Layout from "../../layouts"
import React, {useState, useContext, useEffect} from "react"
import {GlobalStateContext} from "../../context/GlobalContextProvider"
import {Box, Card} from "@mui/material"

import Hider from "../../components/utils/Hider"
import CfpModify from "../../components/cfp/cfpmodify"
import {CfpBackTitle} from "../../components/cfp/cfpdetailstop"
import Cfptb from "../../components/cfp/cfptb"
import {cfpColumns} from "../../components/utils/cfpcolumns"
import CheckTable from "../../components/attendance/checkTable"
import {gUtils} from "../../components/utils/gutils";
import {navigate} from "gatsby";

export default function DevicesGroupDetails({location = {}}) {
    const {state = {}} = location
    const [edit, setEdit] = useState(false)
    const [data, setData] = useState()
    const [title, setTitle] = useState()
    const [items, setItems] = useState([])
    const [ssmItems, setSsmItems] = useState([])
    const [members,setMembers]=useState([])
    const {setCustomModalOpen, gCfp, gIot, setModalContent, gGroupDevice,gStripe} =
        useContext(GlobalStateContext)

    useEffect(() => {
      console.log("当前进入的state", state)
        if (state) {
            setData(state.data)
            setTitle(state.data.name)

            let  po=   state.data.members.map(item=>{
                let  data='常時利用'
                if (item.startTime && item.endTime) {
                    // Convert UNIX timestamps from seconds to milliseconds by multiplying by 1000
                    data=gUtils.getStartTimeEndTime(item)
                }



                return {...item,msgdata:data}

            })
            setMembers(po)
            loadDevices(gCfp.uuidByDevices(state.data.uuids))
            //setItems(gCfp.uuidByDevices(state.data.uuids))
        }else {
            navigate('/')
        }


    }, [state])


    useEffect(() => {

        setSsmItems(gCfp.cfpSsmDevices.filter((item) => !items.some(it => it.deviceUUID === item.deviceUUID)))

    }, [items])

    const loadDevices = async (items) => {
        async function getAllShadow(items) {
            try {
                const promises = items.map((item) => gIot.getShadowWifiBattery(item.deviceUUID));
                const results = await Promise.all(promises);

                console.log("resultsresults", results)
                // 结合原始项和获取的结果
                const ssmWifiList = results.map((result, index) => ({
                    ...result,
                    ...items[index],
                }));

                setItems(ssmWifiList);
                console.log("当前wifi", ssmWifiList);
            } catch (error) {
                console.error("获取阴影信息失败", error);
            }
        }

        if (items.length > 0) {
            await getAllShadow(items);
        }
    }
    useEffect(() => {
        loadDevices(items)
    }, [gCfp.cfpSsmDevices])
    const handleSureClick = (v) => {
        setEdit(false)
        gGroupDevice.updateGroupDevice(
            {
                cid: data.cid,
                gid: data.gid,
                name: v,
            },
            () => {
                setTitle(v)
                setData((item) => ({...item, name: v}))
            }
        )
    }

    const handleEditClick = () => {
        setEdit(true)
    }

    const btnAddDevice = () => {
        setCustomModalOpen(true)
        setModalContent(
            <CheckTable
                title={"デバイスを選択"}
                data={ssmItems} //資料
                selectableRows={"multiple"}
                enableFilter={true}
                useCustomSelection={true}
                setOpenModal={setCustomModalOpen} //開關Modal的屬性
                handleCheck={(value) => {
                    setCustomModalOpen(false)
                    //setItems(prevState => [...prevState,...value])
                    console.log("设备", value)
                    apiAddDevice(value)
                }} //勾選項目後要做的處理function
                location={location}
                isWifi={false}
            />
        )
    }
    const apiAddDevice = async (values) => {

        for (let i = 0; i < values.length; i++) {

            await gGroupDevice.addShareDevices({...values[i], cid: data.cid, did: data.gid, op: 'addm'})

        }


       let devices = values.map((item) => item.deviceUUID)
        let devices2 = items.map((item) => item.deviceUUID)


        let ndata = [...devices2, ...devices]
        gGroupDevice.updateGroupDevice(
            {
                cid: data.cid,
                gid: data.gid,
                uuids: ndata,
            },
            () => {


                loadDevices(gCfp.uuidByDevices(ndata))

            }
        )
    }
    const apiDelDevice = async (values) => {
        /*
            let devices = items.map((item) => item.deviceUUID)
            const filteredArray = data.uuids.filter(
              (element) => !devices.includes(element)
            )
        */
        for (let i = 0; i < values.length; i++) {

            await gGroupDevice.addShareDevices({...values[i], cid: data.cid, did: data.gid, op: 'delm'})

        }


        let ndevices = items.filter(item => !values.some(ite => ite.deviceUUID === item.deviceUUID)).map(data => data.deviceUUID)


        gGroupDevice.updateGroupDevice(
            {
                cid: data.cid,
                gid: data.gid,
                uuids: ndevices,
            },
            () => {
                //   state.data.uuids = filteredArray

                loadDevices(gCfp.uuidByDevices(ndevices))
                //setItems(gCfp.uuidByDevices(ndevices))
            }
        )
    }
    return (
        <Layout location={location}>
            <Card>
                <Hider show={edit}>
                    <CfpModify
                        type="n"
                        state={data}
                        leftClick={() => setEdit(false)}
                        sureClick={handleSureClick}
                    />
                    <Box>
                        <CfpBackTitle title={title} clickEdit={handleEditClick}/>
                    </Box>
                </Hider>
            </Card>
            <Cfptb
                callAdd={btnAddDevice}
                isAdd={true}
                data={items}
                isBind={false}
                isBack={false}
                text={`デバイス(${items.length})`}
                /*   columns={cfpColumns.deviceGroupDetails}*/
                columns={cfpColumns.ssmDevices({datas: items, gIot: gIot})}
                callDelData={apiDelDevice}
            />
            <Cfptb

                isAdd={false}
                isDel={false}
                isCsv={false}
                data={members}
                isBind={false}
                isBack={false}
                adjustToolBar={true}
                text={`グループ鍵`}
                selectableRows={'none'}
                columns={cfpColumns.groupDeviceKey({
                    click:(mid)=>{
                       let result=members.find(item=>item.mid===mid)
                         if (result){

                             result={...result,cid:gStripe.customer.id,isDevice:true,op:'dels'}
                             console.log("删除设备组")
                             gGroupDevice.delMemberDevice(result,(d)=>{

                                 console.log("删除设备组",d)
                                 if (d){
                                     setMembers(prevState => prevState.filter(item=>item.mid!==mid))
                                 }

                             })
                         }


                        console.log("dangqiang",result)

                    }})}

            />
        </Layout>
    )
}
